import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import Layout from "../components/layout";
import Helmet from "react-helmet";

// Specific Typing Keyframes anim based on width of text itself when full length
const Typing = keyframes`
from {
  width: 0px;
}
to {
  width: 911px;
}
`;

const PageContent = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 275px 100px 150px 100px 1fr;
  grid-template-areas:
    "title"
    "welcomeTitle"
    "welcomeMessage"
    "missionTitle"
    "missionStatement";
  background-color: #1c1e1f;
`;

const TurnOn = keyframes`
0% {
  transform: scale(1, .0001) translate3d(0, 0, 0);
  filter: brightness(30);
  opacity: 1;
}
50% {
  transform: scale(1, 1) translate3d(0, 0, 0);
  filter: contrast(0) brightness(0);
  opacity: 0;
}
100% {
  transform: scale(1, 1) translate3d(0, 0, 0);
  filter: contrast(1) brightness(1.2) saturate(1.3);
  opacity: 1;
}
`;

const HomePageTitleArea = styled.span`
  grid-area: title;
  background-color: #121010;

  & div:nth-of-type(1) {
    height: 100%;
    width: 100%;
    background-image: radial-gradient(rgba(0, 18, 5, 0.75), black 130%);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    animation: ${TurnOn} 3s ease-in-out;
    animation-fill-mode: forwards;
    filter: brightness(50);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const HomePageTitle = styled.span`
  display: inline-block;
  font-size: 56px;
  font-family: "Inconsolata", monospace;
  border-right: 15px solid #23fcc7;
  overflow: hidden;
  white-space: nowrap;
  width: 0%;
  text-align: center;
  color: #23fcc7;
  filter: blur(0.03em);
  animation: ${Typing} 3.5s steps(40, end) 3.5s forwards,
    CursorBlink 1s step-end infinite, CoolFlicker 1.6s infinite linear,
    glitch_skew 1s infinite linear alternate-reverse;
  &::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(44px, 450px, 56px, 0px);
    animation: glitch_anim_1 5s infinite linear alternate-reverse;
  }
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    animation: glitch_anim_2 1s infinite linear alternate-reverse;
  }
`;

const Welcome = styled.div`
  font-size: 40px;
  font-weight: bold;
  grid-area: welcomeTitle;
  margin: 15px 0px 10px 25px;
  color: #72ddf7;
  padding-top: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  animation: CoolFlicker 1.55s infinite linear;
`;

const WelcomeTextCont = styled.div`
  font-size: 18px;
  grid-area: welcomeMessage;
  margin: auto;
  width: 90%;
`;

const WelcomeMessageText = styled.span`
  color: #f8f7ff;
`;

const MissionTitle = styled.div`
  font-size: 34px;
  font-weight: bold;
  grid-area: missionTitle;
  padding-top: 50px;
  animation: CoolFlicker 1.55s infinite linear;
  margin: 15px 20px 10px 25px;
  color: #72ddf7;
`;

const MissionTextCont = styled.div`
  grid-area: missionStatement;
  margin: 0 auto;
  width: 90%;
  padding-top: 35px;
`;

const MissionText = styled.span`
  color: #f8f7ff;
`;

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>{`ASC Curriculum Website`}</title>
    </Helmet>
    <PageContent id="pContent">
      {/* Title of the homepage, will add a cool background later */}
      <HomePageTitleArea>
        <div id="homebox">
          <HomePageTitle
            id="homeT"
            data-text="> 2020 ASC SI CURRICULUM WEBSITE"
          >
            > 2020 ASC SI CURRICULUM WEBSITE
          </HomePageTitle>
        </div>
      </HomePageTitleArea>

      {/* Welcome message, will fill out with actual message later */}
      <Welcome className={`homeAnim`}>
        Welcome to the 2020 ASC SI curriculum website
      </Welcome>

      {/* Had two seperate containers for the the text for some more specific styling */}
      <WelcomeTextCont>
        <WelcomeMessageText>
          Welcome to the 2020 ASC Curriculum website! Hope you enjoy it! Here are some tips to using the site:
          <ul>
            <li>Hover over the left navbar to get to the week days.</li>
            <li>Click the star or "Home" to get back here from any page.</li>
            <li>Search for any schedule activity in the appendix page located on the top right.</li>
            <li>Click On A Week to get an overview of all activities for that week.</li>
            <li>On a day page click the arrow on the right to get a quick list of all lectures for that week and all days.</li>
          </ul>
          <br/>
        </WelcomeMessageText>
      </WelcomeTextCont>

      <MissionTitle className={`homeAnim`}>What is All Star Code?</MissionTitle>
      <MissionTextCont>
        <MissionText>
          <strong> Who we are</strong><br/>
          All Star Code is a nonprofit computer science education organization focused on motivated Black and Latino young men. Few organizations prepare this underserved group for careers in the innovation economy. Our ultimate goal is to close the wealth, income and opportunity gaps.
          <br/><br/>
          <strong> What we do</strong><br/>
          All Star Code is developing a new generation of entrepreneurs who have the tools they need to succeed in a technological world. We give young men the skills, networks, and mindsets they need through our flagship program, the Summer Intensive, a six-week coding bootcamp, and our continued Alumni Services.
        </MissionText>
      </MissionTextCont>
    </PageContent>
  </Layout>
)

export default IndexPage
